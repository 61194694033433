<template>
    <div class="lg:col-span-2">
        <h2 class="text-base font-semibold leading-7 text-left text-gray-900">Información General del
            Informe</h2>
        <p class="mt-1 text-sm leading-6 text-left text-gray-600">La sección de Información General del
            Informe proporciona un resumen conciso y detallado del contenido esencial del informe.</p>

        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-12 mt-4">
            <div class="md:col-span-6">
                <div class="text-left">
                    <label class="text-base">Empresa</label>
                </div>
                <form class="flex items-center">
                    <div class="relative w-full">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                        </div>
                        <div>
                            <input v-model="companySearch" @input="handleSearch()" type="text"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Buscar la empresa..." required>
                            <ul v-show="showSuggestions" ref="suggestionsList"
                                class="bg-white border border-gray-300 mt-2 w-full rounded-lg shadow-lg absolute z-3"
                                style="top: calc(100% + 8px);">
                                <li v-for="suggestion in companySuggestions" :key="suggestion.id"
                                    @click="selectSuggestion(suggestion)"
                                    class="py-2 px-4 cursor-pointer hover:bg-gray-100">
                                    <div class="text-left">
                                        <p>{{ suggestion.company_name }}</p>
                                    </div>
                                </li>
                                <li v-if="showSuggestions && companySuggestions.length == 0"
                                    class="py-3 px-4 cursor-pointer hover:bg-gray-100" @click="createNewCompany()">
                                    <div class="text-left">
                                        <p>Crear nueva empresa: {{ companySearch }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
                <div v-if="(userTriedToSave || userTriedToCreate) && companySelected == null" class="text-left">
                    <span class="text-red-500">Aún no se ha seleccionado la empresa</span>
                </div>
            </div>
            <div class="md:col-span-6"></div>

            <div class="md:col-span-6">
                <div class="text-left">
                    <label class="text-base">Clientes</label>
                </div>
                <Multiselect :initialOptionsSelected="clientsSelected" :disabled="companySelected == null" :noOptionsText="'No se encontraron clientes'" :loading="loadingClients" :options="clientSuggestions" 
                    @onSelectedOptions="onSelectedClientOptions"/>
                <div v-if="userTriedToSave && clientsSelected.length == 0" class="text-left">
                    <span class="text-red-500">Aún no se ha seleccionado algún cliente</span>
                </div>
            </div>
            <div class="md:col-span-6"></div>

            <div class="md:col-span-6">
                <div class="text-left">
                    <label class="text-base">Título del informe</label>
                </div>
                <textarea v-model="title" class="h-[7rem] border mt-1 rounded p-1 w-full bg-gray-50 resize-none"></textarea>
                <div v-if="(userTriedToSave || userTriedToCreate) && title.length == 0" class="text-left">
                    <span class="text-red-500">Requerido</span>
                </div>
            </div>

            <div class="md:col-span-6 h-[7rem]">
                <div class="grid grid-rows-1 grid-flow-col gap-1 my-3">
                    <div class="mx-1">
                        <div class="text-left">
                            <label class="text-base">Fecha de realización</label>
                        </div>
                        <VDatePicker v-model="completionDate" :popover="false">
                            <template #default="{ togglePopover, inputValue, inputEvents }">
                                <div class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden">
                                    <button
                                        class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
                                        @click="() => togglePopover()">
                                        <i class="far fa-calendar"></i>
                                    </button>
                                    <input :value="inputValue" v-on="inputEvents" @click="() => togglePopover()"
                                        class="flex-grow px-2 py-1 w-full bg-gray-50" />
                                </div>
                            </template>
                        </VDatePicker>
                    </div>
                    <div class="mx-1">
                        <div class="text-left">
                            <label class="text-base">Fecha del Informe Técnico</label>
                        </div>
                        <VDatePicker v-model="technicalReportDate" :popover="false">
                            <template #default="{ togglePopover, inputValue, inputEvents }">
                                <div class="flex rounded-lg border border-gray-300 dark:border-gray-600 overflow-hidden">
                                    <button
                                        class="flex justify-center items-center px-2 bg-accent-100 hover:bg-accent-200 text-accent-700 border-r border-gray-300 dark:bg-gray-700 dark:text-accent-300 dark:border-gray-600 dark:hover:bg-gray-600"
                                        @click="() => togglePopover()">
                                        <i class="far fa-calendar"></i>
                                    </button>
                                    <input :value="inputValue" v-on="inputEvents" @click="() => togglePopover()"
                                        class="flex-grow px-2 py-1 w-full bg-gray-50" />
                                </div>
                            </template>
                        </VDatePicker>
                    </div>

                </div>

                <div>
                    <div class="text-left">
                        <label class="text-base">Ubicación</label>
                    </div>
                    <input v-model="location" class="border rounded p-1 w-full bg-gray-50" />
                    <div v-if="(userTriedToSave || userTriedToCreate) && location.length == 0" class="text-left">
                        <span class="text-red-500">Requerido</span>
                    </div>
                </div>
            </div>

            <div class="md:col-span-3">
                <div class="text-left">
                    <label class="text-base">Transformadores intervenidos</label>
                </div>
                <input type="number" v-model="intervenedTransformers" class="border rounded p-1 w-full bg-gray-50" />
            </div>
            <div class="md:col-span-9"></div>

            <div class="md:col-span-6">
                <div class="text-left">
                    <label class="text-base">Antecedentes</label>
                </div>
                <textarea v-model="antecedents"
                    class="h-[7rem] border mt-1 rounded p-2 w-full bg-gray-50 resize-none"></textarea>
                <div v-if="userTriedToSave && antecedents.length == 0" class="text-left">
                    <span class="text-red-500">Requerido</span>
                </div>
            </div>

            <div class="md:col-span-6">
                <div class="text-left">
                    <label class="text-base">Alcance</label>
                </div>
                <textarea v-model="scope" class="h-[7rem] border mt-1 rounded p-1 w-full bg-gray-50 resize-none"></textarea>
                <div v-if="userTriedToSave && scope.length == 0" class="text-left">
                    <span class="text-red-500">Requerido</span>
                </div>
            </div>

        </div>

        <div class="md:col-span-12 my-3 border-t border-gray-300"></div>

        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 grid-flow-row md:grid-cols-12 mt-4">
            <div class="md:col-span-5">
                <h2 class="text-base font-semibold leading-7 text-left text-gray-900 mt-4">Objetivos del
                    informe</h2>
                <p class="mt-1 text-sm text-left leading-6 text-gray-600">La sección de Objetivos del
                    Informe establece claramente los propósitos y metas del documento.</p>
            </div>

            <div class="md:col-span-7">
                <div class="text-left">
                    <label class="text-base">Objetivo General</label>
                </div>
                <textarea v-model="generalObjective"
                    class="h-[4rem] border mt-1 rounded p-1 w-full bg-gray-50 resize-none"></textarea>
                <div v-if="userTriedToSave && scope.length == 0" class="text-left">
                    <span class="text-red-500">Requerido</span>
                </div>
                <div class="text-left mt-4">
                    <label class="text-base">Objetivos Específicos</label>
                </div>
                <div class="max-h-[15rem] overflow-y-auto">
                    <div v-for="(specificObjective, index) in specificObjectives" :key="index" class="flex mb-2">
                        <textarea v-model="specificObjectives[index]"
                            class="h-10 border mt-1 rounded p-1 w-full bg-gray-50 resize-none"></textarea>

                        <button @click="removeSpecificObjective(index)"
                            class="ml-2 mt-1 px-4 py-1 bg-red-500 text-white rounded">
                            <i class="far fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
                <div v-if="userTriedToSave && scope.length == 0" class="text-left">
                    <span class="text-red-500">Se requiere al menos un objetivo específico</span>
                </div>
                <div class="text-left mt-3">
                    <button @click="addSpecificObjective()"
                        class="px-4 py-2 bg-green-500 text-left text-white rounded">Agregar
                        Objetivo</button>
                </div>
            </div>
        </div>

        <div class="md:col-span-12 my-3 border-t border-gray-300"></div>

        <div class="flex mt-5 items-center justify-between space-x-4">
            <div class="flex items-center justify-start space-x-4">
                <!-- <button class="btn-cancel font-bold py-2 px-4 rounded mr-3" @click="cancelChanges()">Cancelar</button> -->
            </div>
            <div class="flex items-center justify-end space-x-4">
                <button @click="saveReport()" :disabled="loadingSave || (!minimumDataIsValid && userTriedToSave)"
                    :class="{ 'opacity-50 cursor-not-allowed': loadingSave}"
                    class="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    <span v-if="loadingSave" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Guardar
                </button>
                <button @click="finishGeneralReport()" :disabled="loadingFinish || reportId == null"
                    :class="{ 'opacity-50 cursor-not-allowed text-hover': loadingFinish || reportId == null }"
                    class="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    <span v-if="loadingFinish" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                    Siguiente
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import Navbar from '../../components/Navbar';
import Datatable from '../../components/Datatable';
import Multiselect from '../../components/Multiselect';
import Activities from './Activities.vue';
import Stepper from './Stepper.vue';
import { mapGetters } from 'vuex';
import { ref } from 'vue';

export default {
    components: { Navbar, Datatable, Stepper, Activities, Multiselect, Multiselect },
    data() {
        return {
            currentRouteName: '',
            reportId: null,

            currentStep: 1,
            loadingSave: false,
            loadingFinish: false,

            // Report Data
            title: '',
            location: '',
            intervenedTransformers: 0,
            antecedents: '',
            scope: '',
            completionDate: ref(new Date()),
            technicalReportDate: ref(new Date()),

            // Objectives
            generalObjective: '',
            specificObjectives: [
                ''
            ],

            // Company Search
            companySearch: '',
            showSuggestions: false,
            companySuggestions: [],
            companySelected: null,

            // Clients Search
            clientSearch: '',
            loadingClients: false,
            clientSuggestions: [],
            clientsSelected: [],

            initialData: {},
            userTriedToSave: false,
            userTriedToCreate: false
        }
    },
    mounted() {
        this.currentRouteName = this.$route.name;

        if (this.currentRouteName) {
            this.reportId = this.$route.params.reportId;
            this.getReportData();
        }

        window.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
        window.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
        getReportData() {
            let url = `${this.getApiUrl}/reports/get-general-report-data/${this.reportId}`;
            let token = this.getUserData.token;

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 200) {
                        this.title = data.payload.title;
                        this.antecedents = data.payload.antecedents;
                        this.generalObjective = data.payload.generalObjective;
                        this.specificObjectives = data.payload.specificObjectives;
                        this.completionDate = ref(new Date(data.payload.completionDate));
                        this.technicalReportDate = ref(new Date(data.payload.technicalReportDate));
                        this.intervenedTransformers = data.payload.intervenedTransformers;
                        this.location = data.payload.location;
                        this.scope = data.payload.scope;
                        this.companySelected = data.payload.company;
                        this.companySearch = data.payload.company ? data.payload.company.company_name : null;
                        this.initialData = data;
                        this.clientsSelected = data.payload.clientsSelected.map((option) => option.user_id);
                        this.searchClientSuggestions();
                    } else {
                        this.showMsg('error', 'Informes', 'Error al obtener el informe');
                        this.loadingSave = false;
                        this.$router.push({ name: 'ReportList' });
                    }
                });
        },
        cancelChanges() {
            this.title = this.initialData.payload.title;
            this.antecedents = this.initialData.payload.antecedents;
            this.generalObjective = this.initialData.payload.generalObjective;
            this.specificObjectives = this.initialData.payload.specificObjectives;
            this.completionDate = ref(new Date(this.initialData.payload.completionDate));
            this.technicalReportDate = ref(new Date(this.initialData.payload.technicalReportDate));
            this.intervenedTransformers = this.initialData.payload.intervenedTransformers;
            this.location = this.initialData.payload.location;
            this.scope = this.initialData.payload.scope;
            this.companySelected = this.initialData.payload.company;
            this.companySearch = this.initialData.payload.company.company_name;
        },
        finishGeneralReport() {
            if (!this.newReportDataIsValid) {
                this.userTriedToSave = true
                this.showMsg('warning', 'Informes', 'Por favor, complete todos los campos requeridos');
                return;
            }

            this.loadingFinish = true;
            let url = `${this.getApiUrl}/reports/update`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportId,
                title: this.title,
                antecedents: this.antecedents,
                scope: this.scope,
                completion_date: this.formatDate(this.completionDate),
                technical_report_completion_date: this.formatDate(this.technicalReportDate),
                intervened_transformers: this.intervenedTransformers,
                location: this.location,
                company: this.companySelected,
                general_objective: this.generalObjective,
                specific_objectives: this.specificObjectives,
                finish: true,
                clients_selected: this.clientsSelected,
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 201) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.$emit('changeCurrentStep', 2);
                            this.loadingFinish = false;
                        }, 300)
                    }
                });
        },
        saveReport() {
            if (this.reportId) {
                this.updateReport();
            } else {
                this.createReport();
            }
        },
        updateReport() {
            if(!this.minimumDataIsValid) {
                this.userTriedToCreate = true;
                this.showMsg('warning', 'Informes', 'Por favor, complete todos los campos requeridos');
                return;
            }

            this.loadingSave = true;
            let url = `${this.getApiUrl}/reports/update`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                reportId: this.reportId,
                title: this.title,
                antecedents: this.antecedents,
                scope: this.scope,
                completion_date: this.formatDate(this.completionDate),
                technical_report_completion_date: this.formatDate(this.technicalReportDate),
                intervened_transformers: this.intervenedTransformers,
                location: this.location,
                company: this.companySelected,
                general_objective: this.generalObjective,
                specific_objectives: this.specificObjectives,
                clients_selected: this.clientsSelected
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },

            })
                .then(response => response.json())
                .then(data => {
                    if (data.status == 201) {
                        setTimeout(() => {
                            this.showMsg('success', 'Informes', 'Informe actualizado satisfactoriamente');
                            this.loadingSave = false;
                        }, 300)
                    }
                });
        },
        createReport() {
            if(!this.minimumDataIsValid) {
                this.showMsg('warning', 'Informes', 'Por favor, complete todos los campos requeridos');
                this.userTriedToCreate = true;
                return;
            }

            this.loadingSave = true;
            let url = `${this.getApiUrl}/reports/create`;
            let token = this.getUserData.token;
            let body = JSON.stringify({
                title: this.title,
                antecedents: this.antecedents,
                scope: this.scope,
                completion_date: this.formatDate(this.completionDate),
                technical_report_completion_date: this.formatDate(this.technicalReportDate),
                intervened_transformers: this.intervenedTransformers,
                location: this.location,
                company: this.companySelected,
                general_objective: this.generalObjective,
                specific_objectives: this.specificObjectives,
                clients_selected: this.clientsSelected
            });

            fetch(url, {
                method: 'POST',
                body: body,
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                },
            })
                .then(response => response.json())
                .then(data => {
                    let reportId = data.reportId;
                    if (data.status == 201) {
                        setTimeout(() => {
                            this.showMsg('success', 'Nuevo Informe', 'Informe creado satisfactoriamente');
                            this.loadingSave = false;
                            this.$router.push({ name: 'EditReport', params: { reportId } });
                        }, 300)
                    }
                });
        },
        addSpecificObjective() {
            this.specificObjectives.push("");
        },
        removeSpecificObjective(index) {
            if(this.specificObjectives.length > 1) {
                this.specificObjectives.splice(index, 1);
            } 
            else {
                this.specificObjectives[0] = "";
            }
        },
        handleSearch() {
            this.showSuggestions = this.companySearch.length >= 1;
            if (this.showSuggestions) {
                let url = `${this.getApiUrl}/company/companies-suggestions/${this.companySearch}`;
                let token = this.getUserData.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': token
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.companySuggestions = data.companies;
                    })
            }
        },
        selectSuggestion(suggestion) {
            this.companySearch = suggestion.company_name;
            this.companySelected = suggestion;
            this.showSuggestions = false;
            this.searchClientSuggestions();
        },
        searchClientSuggestions() {
            this.loadingClients = true;
            let selectedCompanyId = this.companySelected ? this.companySelected.id : null;
            if (selectedCompanyId != null) {
                let url = `${this.getApiUrl}/company/client-suggestions/${selectedCompanyId}`;
                let token = this.getUserData.token;

                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': token
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        this.clientSuggestions = data.clients;
                    })
                    .finally(() => {
                        this.loadingClients = false;
                    });
            }
        },
        onSelectedClientOptions(selectedOptions) {
            this.clientsSelected = selectedOptions;
        },
        handleClickOutside(event) {
            const suggestionsList = this.$refs.suggestionsList;
            if (suggestionsList && !this.$refs.suggestionsList.contains(event.target)) {
                this.showSuggestions = false;
            }
        },
        createNewCompany() {
            this.$router.push({ name: 'CreateCompany' });
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");

            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    },
    computed: {
        ...mapGetters(['getApiUrl', 'getUserData']),
        newReportDataIsValid() {
            let validations = [
                this.title.length > 0,
                this.antecedents.length > 0,
                this.scope.length > 0,
                this.location.length > 0,
                this.companySelected != null,
                this.generalObjective.length > 0,
                this.specificObjectives.length > 0,
                this.specificObjectives[0].length > 0,
                this.clientsSelected.length > 0,
            ]

            return validations.every(validation => validation);
        },

        minimumDataIsValid() {
            let validations = [
                this.companySelected != null,
                this.title.length > 0,
                this.location.length > 0,
            ]

            return validations.every(validation => validation);
        }
    }
}
</script>

<style scoped>
.btn-save {
    font-family: 'Nunito Sans';
    background-color: #FFA500;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    ;
}

.btn-save:hover {
    background-color: #117bc3;
}

.btn-cancel {
    font-family: 'Nunito Sans', sans-serif;
    letter-spacing: normal;
    box-shadow: 0 0 0 1px rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.1), 0 2px 3px 0 rgba(50, 50, 93, 0.08), 0 1px 1.5px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
</style>
<template>
	<div>
		<h3 class="text-l font-semibold mb-4">{{ title }}</h3>
		<Line :data="chartData" :options="chartOptions"></Line>
	</div>
</template>
  
<script>
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
)

export default {
	components: { Line },
	props: {
		data: {
			type: Object,
			required: true
		},
		title: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			chartOptions: {
				responsive: true,
			},
		};
	},
	computed: {
		chartData() {
			return {
				// Datos del gráfico
				labels: this.data.labels,
				datasets: [
					{
						label: "Reportes Registrados",
						backgroundColor: "rgba(75, 192, 192, 0.2)",
						borderColor: "rgba(75, 192, 192, 1)",
						borderWidth: 1,
						data: this.data.data,
					},
				],
			}
		}
	}
};
</script>
  